import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section, Image, Button } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Оренда яхт AzureWave</title>
        <meta
          name={"description"}
          content={"Досліджуйте моря зі стилем і простотою"}
        />
        <meta property={"og:title"} content={"Оренда яхт AzureWave"} />
        <meta
          property={"og:description"}
          content={"Досліджуйте моря зі стилем і простотою"}
        />
        <meta
          property={"og:image"}
          content={"https://up.lixorev.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://up.lixorev.com/img/image93.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://up.lixorev.com/img/image93.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://up.lixorev.com/img/image93.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://up.lixorev.com/img/image93.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://up.lixorev.com/img/image93.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://up.lixorev.com/img/image93.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://up.lixorev.com/img/1.jpg) center/cover"
        padding="160px 0"
        sm-padding="40px 0"
        color="--light"
        font="--base"
      >
        <Box margin="0" display="flex">
          <Box
            display="flex"
            padding="16px 16px 16px 16px"
            width="75%"
            md-width="100%"
          >
            <Box display="flex" flex-direction="column">
              <Text
                as="h1"
                font="--headline1"
                md-font="--headline2"
                margin="10px 0 40px"
              >
                AzureWave <br />
                Оренда яхт
              </Text>
              <Text as="p" font="--lead" margin="20px 0">
                Ласкаво просимо до AzureWave Yacht Charters, де починаються ваші
                морські пригоди! Незалежно від того, чи плануєте ви сонячну
                одноденну подорож, чи нічну подорож під зоряним небом, наш флот
                комфортабельних яхт зробить вашу мандрівку незабутньою. Відчуйте
                гострі відчуття відкритого моря з легкістю та інтимністю, які
                може запропонувати лише приватна яхта.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section padding="80px 0 90px 0" quarkly-title="Headline-4">
        <Override slot="SectionContent" sm-min-width="280px" />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          lg-flex-direction="column"
        >
          <Text
            margin="0px 0px 0px 0px"
            width="50%"
            font="normal 500 50px/1.2 --fontFamily-serifTimes"
            padding="0px 50px 0px 0px"
            lg-width="100%"
            lg-margin="0px 0px 50px 0px"
            md-padding="0px 0 0px 0px"
            md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
            sm-margin="0px 0px 35px 0px"
          >
            Про нас
          </Text>
          <Box
            min-width="100px"
            min-height="100px"
            width="50%"
            display="flex"
            flex-direction="column"
            justify-content="center"
            lg-width="100%"
          >
            <Text
              margin="0px 0px 20px 0px"
              font="--lead"
              color="#727a82"
              sm-margin="0px 0px 25px 0px"
            >
              AzureWave Yacht Charters спеціалізується на індивідуальній оренді
              яхт, які підходять як для одноденних, так і для багатоденних
              екскурсій. Наші судна забезпечують ідеальне поєднання комфорту та
              функціональності, що робить їх ідеальними як для відпочинку, так і
              для досліджень. Від затишних кают для ночівлі до просторих
              сонячних палуб - наші яхти обладнані так, щоб ваш час на воді був
              одночасно розслаблюючим і захоплюючим.
            </Text>
          </Box>
        </Box>
      </Section>
      <Section padding="90px 0 100px 0" quarkly-title="List-5">
        <Text
          margin="0px 0px 90px 0px"
          text-align="center"
          font="normal 600 42px/1.2 --fontFamily-sans"
          md-margin="0px 0px 60px 0px"
        >
          Чому варто обрати AzureWave?
        </Text>
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(2, 1fr)"
          grid-gap="56px 34px"
          lg-grid-template-columns="repeat(2, 1fr)"
          sm-grid-template-columns="1fr"
          sm-grid-gap="36px 0"
          md-grid-gap="50px 0"
          margin="0px 130px 0px 130px"
          lg-margin="0px 0 0px 0"
          md-grid-template-columns="1fr"
        >
          <Box
            min-width="10px"
            min-height="10px"
            display="flex"
            flex-direction="column"
            padding="0px 50px 0px 0px"
            align-items="flex-start"
            md-padding="0px 0 0px 0px"
          >
            <Text
              margin="0px 0px 25px 0px"
              font="normal 500 28px/1.2 --fontFamily-sans"
              md-margin="0px 0px 20px 0px"
            >
              Персоналізовані маршрути:
            </Text>
            <Text
              margin="0px 0px 35px 0px"
              font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
              color="#474a4d"
              md-margin="0px 0px 25px 0px"
            >
              Сплануйте свою подорож на власний смак, будь то стоянка в тихих
              бухтах або круїзи вздовж жвавого узбережжя.
            </Text>
          </Box>
          <Image
            src="https://up.lixorev.com/img/2.jpg"
            display="block"
            width="100%"
            md-order="-1"
          />
          <Image
            src="https://up.lixorev.com/img/3.jpg"
            display="block"
            width="100%"
          />
          <Box
            min-width="10px"
            min-height="10px"
            display="flex"
            flex-direction="column"
            padding="0px 30px 0px 20px"
            align-items="flex-start"
            md-padding="0px 0 0px 0"
          >
            <Text
              margin="0px 0px 25px 0px"
              font="normal 500 28px/1.2 --fontFamily-sans"
              md-margin="0px 0px 20px 0px"
            >
              Універсальний флот:
            </Text>
            <Text
              margin="0px 0px 35px 0px"
              font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
              color="#474a4d"
              md-margin="0px 0px 25px 0px"
            >
              Наш асортимент яхт включає варіанти зі спальними приміщеннями та
              без них, які ідеально підходять для будь-якої подорожі.
            </Text>
          </Box>
          <Box
            min-width="10px"
            min-height="10px"
            display="flex"
            flex-direction="column"
            padding="0px 50px 0px 0px"
            align-items="flex-start"
            md-padding="0px 0 0px 0px"
          >
            <Text
              margin="0px 0px 25px 0px"
              font="normal 500 28px/1.2 --fontFamily-sans"
              md-margin="0px 0px 20px 0px"
            >
              Досвідчений екіпаж:
            </Text>
            <Text
              margin="0px 0px 35px 0px"
              font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
              color="#474a4d"
              md-margin="0px 0px 25px 0px"
            >
              Кваліфіковані та обізнані члени нашого екіпажу гарантують безпечну
              та приємну подорож.
            </Text>
            <Button
              href="/contacts"
              type="link"
              text-decoration-line="initial"
              background="--color-primary"
              padding="11px 28px 11px 28px"
              border-radius="25px"
            >
              Звʼяжіться з нами
            </Button>
          </Box>
          <Image
            src="https://up.lixorev.com/img/4.jpg"
            display="block"
            width="100%"
            md-order="-1"
          />
        </Box>
      </Section>
      <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
        <Override
          slot="SectionContent"
          flex-direction="row"
          md-flex-wrap="wrap"
          flex-wrap="wrap"
        />
        <Box
          width="100%"
          margin="0px 0px 64px 0px"
          md-margin="0px 0px 30px 0px"
        >
          <Text
            margin="0px 0px 0px 0px"
            color="--dark"
            font="--headline2"
            lg-width="100%"
            lg-text-align="center"
          >
            Готові до подорожі?
          </Text>
        </Box>
        <Box display="flex" width="100%" md-flex-wrap="wrap">
          <Box
            display="flex"
            flex-direction="column"
            justify-content="space-between"
            width="100%"
            padding="0px 0px 0px 0px"
            md-padding="0px 0px 0px 0px"
            md-margin="0px 0px 40px 0px"
            margin="0px 3% 0px 0px"
          >
            <Text
              margin="0px 0px 0px 0px"
              color="--darkL2"
              font="--base"
              md-text-align="center"
            >
              Вирушайте у свою наступну морську пригоду з AzureWave - де океан
              належить вам
            </Text>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
